export default {
  API_URL: "https://api-cbre-prod.findyourspot.io",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.findyourspot.io",
  CLIENT: "cbre",
  GOOGLE_MAPS_API_KEY: "AIzaSyB-W7LTVIx0tRTFT2lvYmYcc47uKrzIaeU",
  HOME_URL: "https://iberia.findyourspot.io",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.findyourspot.io/all/media/Fotos+de+portada+para+HOME/Foto+Centro+1.jpg",
  HOME_COMMENT_TEXT: "LAS ACCIONES TEMPORALES DE BRANDING EN CBRE SON TODO UN ÉXITO, YA QUE SUS LOCALES Y SU PÚBLICO SON ÚNICOS EN ESPAÑA",
  HOME_COMMENT_AUTHOR: "Nike Director",
  HOME_COMMENT_LINK: "https://www.cbre.es/en/services/industries-and-specialties/retail",
  HOME_COMMENT_IMAGE: "https://files.findyourspot.io/all/media/BAC/foto_branding.png",
  LOGO_LIGHT: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-light.png",
  LOGO_DARK: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-dark.png",
  ADMIN_DEFAULT_LOCALE: "en-cbre",
  TENANT_DEFAULT_LOCALE: "es-cbre",
  ABOUT_US: "",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/CBRE Privacy Policy.pdf",
  SOCIAL_LINKS: {"facebook":"https://www.facebook.com/cbreespana/","twitter":"https://twitter.com/CBRE_es?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor","instagram":"https://www.instagram.com/cbre_spain/?hl=es"},
  LEVEL_NAMES: ["-1", "0", "1", "2", "3", "4"],
  ADVERTISING_IMG: "https://files.findyourspot.io/all/media/912de3e4-b8bc-4a9e-bbf4-f3c0d5ffb6ef.jpg",
  REVERB_APP_KEY: "wRxTG38sPcZhOnf2dGVTifDS",
  REVERB_HOST: "api-cbre-prod.findyourspot.io",
  REVERB_AUTH_ENDPOINT: "https://api-cbre-prod.findyourspot.io/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap",
  INDEXABLE: "true",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","es-cbre","en-cbre","pt-cbre","fr-cbre"],
  MAPBOX_API_KEY: "pk.eyJ1IjoibWF4aW1pbGlhbm9iYWMiLCJhIjoiY2wxd2U5YXFrMGNzajNjbzE3MmZrNXljeiJ9.s4gzv9rbU479joxwhHVJkA",
  HOME_MAP_STYLE: "mapbox://styles/maximilianobac/cl27ugios001015m8y0wpbi8k",
  HOME_MAP_BOUNDS: [[-12.009268786989923,35.42444506829712],[4.689952860428149,44.307756734957486]],
  MAPBOX_MARKER: "https://files.findyourspot.io/all/markers/mapbox-icon-CBRE.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "",
  COGNITO_URL: "",
}
