
  import t0 from "./theme-client";
  import t24 from "./theme.asset-24.js";
import t1 from "./theme.asset-1.js";
import t2 from "./theme.asset-2.js";
import t3 from "./theme.asset-3.js";
import t4 from "./theme.asset-4.js";
import t5 from "./theme.asset-5.js";
import t6 from "./theme.asset-6.js";
import t7 from "./theme.asset-7.js";
import t8 from "./theme.asset-8.js";
import t11 from "./theme.asset-11.js";
import t12 from "./theme.asset-12.js";
import t13 from "./theme.asset-13.js";
import t15 from "./theme.asset-15.js";
import t16 from "./theme.asset-16.js";
import t17 from "./theme.asset-17.js";
import t18 from "./theme.asset-18.js";
import t19 from "./theme.asset-19.js";
import t20 from "./theme.asset-20.js";
import t21 from "./theme.asset-21.js";
import t22 from "./theme.asset-22.js";
import t23 from "./theme.asset-23.js";


  export const themes = {  24: t24,
 1: t1,
 2: t2,
 3: t3,
 4: t4,
 5: t5,
 6: t6,
 7: t7,
 8: t8,
 11: t11,
 12: t12,
 13: t13,
 15: t15,
 16: t16,
 17: t17,
 18: t18,
 19: t19,
 20: t20,
 21: t21,
 22: t22,
 23: t23,
 }

  export const getThemeById = (id) => id && themes[id] ? themes[id] : t0

  export default t0;
